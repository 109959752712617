<template>
  <div class="row pa-md-10">
    <div class="col-sm-12 col-md-6 pr-0">
      <v-card class="pa-5">
        <v-card-title
            class="headline"
            v-text="'Payment instructions'"
        ></v-card-title>

        <v-card-subtitle v-text="'Please follow the instructions to complete your payment'"></v-card-subtitle>
        <v-card-text>
          কোর্সটি কিনতে নিচের বিকাশ/রকেট/নগদ নাম্বারে {{ payable }} টাকা সেন্ড মানি করুন।
          সেন্ড মানি সম্পন্ন হয়ে গেলে নিম্নোক্ত ঘরে যে নাম্বার থেকে টাকা পাঠিয়েছেন তা লিখুন এবং নিচের ঘরে ট্রাঞ্জেকশন
          আইডি লিখে সাবমিট করুন।
          এডমিন প্যালেন থেকে পেমেন্ট ভেরিফাই করার পর আপনার কোর্সটি একটিভ হয়ে যাবে।
          <v-list>
            <v-list-item v-if="settings.bkash">
              <v-list-item-content>বিকাশঃ {{ settings.bkash }}
              </v-list-item-content>
            </v-list-item>
            <v-list-item v-if="settings.rocket">
              <v-list-item-content>
                রকেটঃ {{ settings.rocket }}
              </v-list-item-content>
            </v-list-item>
            <v-list-item v-if="settings.nagad">
              <v-list-item-content>
                নগদঃ {{ settings.nagad }}
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>
    </div>
    <div class="col-sm-12 col-md-6 pl-0">
      <v-card class="pa-5">
        <v-card-title
            class="headline"
            v-text="'Payment instructions'"
        ></v-card-title>

        <v-card-subtitle v-text="'Please follow the instructions to complete your payment'"></v-card-subtitle>
        <v-card-text>

          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>Price</v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
              <div v-if="course.discount">
                ৳{{ price }} <span class="red--text"><del>৳{{ course.price }}</del></span>
              </div>
              <div v-else>
                ৳{{ price }}
              </div>
            </v-list-item-action>
          </v-list-item>

          <div v-if="!coupon.discount && allowCoupon">
            <v-list-item v-if="!couponField">
              <v-list-item-content>
                <a class="cursor-pointer" @click="couponField=true">Click here to use coupon code
                </a>
              </v-list-item-content>
            </v-list-item>
            <v-list-item v-if="couponField">
              <v-list-item-content>
                <v-row>
                  <v-col
                      cols="12"
                      sm="8"
                  >
                    <v-text-field
                        v-model="inputCoupon"
                        label="Insert coupon code"
                        :error-messages="errorMessage"
                    >
                      <template
                          v-if="inputCoupon.length > 0"
                          v-slot:append
                      >
                        <v-btn
                            text
                            rounded color="primary"
                            @click="submitCoupon"
                        >
                          Submit
                        </v-btn>
                      </template>
                    </v-text-field>
                  </v-col>
                </v-row>
              </v-list-item-content>
            </v-list-item>
          </div>

          <v-list-item v-if="coupon.discount">
            <v-list-item-content>
              <v-list-item-title>Coupon</v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
              <div class="red--text">{{ coupon.discount }}</div>
            </v-list-item-action>
          </v-list-item>

          <v-divider></v-divider>

          <v-list-item>
            <v-list-item-content>
              <v-list-item-title class="font-weight-bold">Payable</v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
              <div class="font-weight-bold">৳{{ payable }}</div>
            </v-list-item-action>
          </v-list-item>
          <v-row align-content="center" v-if="online">
            <v-col class="text-center">
              <v-btn rounded class="white--text green" block @click="proceedToPay">Proceed to pay</v-btn>
            </v-col>
          </v-row>
          <v-row align-content="center" v-if="online">
            <v-col class="text-center">
              <v-btn rounded link @click="authenticated ? manual=!manual : $router.push({name: 'Login'})" outlined>
                <div v-if="manual">
                  Click here to hide manual payment
                </div>
                <div v-else>
                  Click here for manual payment
                </div>
              </v-btn>
            </v-col>
          </v-row>
          <div v-if="manual">
            <div v-if="payment" style="border-bottom: 2px solid green;">
              <blockquote>
                We have accepted your payment information. Please wait until your payment is verified. Thank you!
              </blockquote>
            </div>
            <div>
              <v-form
                  ref="form"
                  v-model="valid"
                  lazy-validation
              >
                <v-text-field
                    v-if="!!payable"
                    v-model="phone"
                    :rules="phoneRules"
                    label="Phone Number (number you have used for payment)"
                    required
                ></v-text-field>

                <v-text-field
                    v-if="!!payable"
                    v-model="transaction_id"
                    :rules="transactionIdRules"
                    label="Transaction ID"
                    required
                ></v-text-field>

                <v-btn
                    :disabled="!isValidForm"
                    v-if="payment"
                    color="success"
                    class="mr-4"
                    @click="update()"
                >
                  Update
                </v-btn>

                <v-btn
                    :disabled="!isValidForm"
                    v-else
                    color="success"
                    class="mr-4"
                    @click="submit()"
                >
                  Submit
                </v-btn>
              </v-form>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>
<script>
import axios from 'axios'
import Swal from 'sweetalert2'
import {mapGetters} from "vuex";

export default {
  data: () => ({
    valid: true,
    manual: !(process.env.VUE_APP_ONLINE_PAYMENT === 'true'),
    online: (process.env.VUE_APP_ONLINE_PAYMENT === 'true'),
    allowCoupon: process.env.VUE_APP_COUPON === 'true',
    course: '',
    phone: '',
    coupon: '',
    errorMessage: '',
    inputCoupon: '',
    couponField: false,
    phoneRules: [
      v => !!v || 'Phone number is required',
    ],
    transaction_id: '',
    transactionIdRules: [
      v => !!v || 'Transaction id is required',
    ],
    payment: '',
    settings: {},
  }),
  computed: {
    ...mapGetters({
      authenticated: 'auth/authenticated',
      user: 'auth/user'
    }),
    price() {
      let price = this.course.price
      let discount = this.course.discount || 0
      return price - discount
    },
    payable() {
      let price = this.price
      let discount = this.coupon.discount || 0
      return price - discount
    },
    isValidForm() {
      return !this.payable || (this.phone.length === 11 && this.transaction_id.length === 10)
    }
  },
  methods: {
    proceedToPay() {
      if (this.authenticated) {
        location.href = process.env.VUE_APP_BACKEND + '/api/pay/confirm?user=' + this.user.id + '&course=' + this.course.id + '&coupon=' + this.inputCoupon
      } else {
        this.$router.push({name: 'Login'})
      }
    },
    submit() {
      const url = 'payment';
      let data = {
        phone: this.phone,
        transaction_id: this.transaction_id,
        amount: this.payable,
        course_id: this.course.id,
        coupon: this.inputCoupon,
      };
      axios.post(url, data).then(() => {
        Swal.fire(
            'Successful!',
            'Please wait for the confirmation',
            'success'
        );
        this.loadCourse();
      }).catch((error) => {
        console.log(error);
      })
    },
    update() {
      const url = 'payment/' + this.payment.id;
      let data = {
        phone: this.phone,
        transaction_id: this.transaction_id,
      };
      axios.put(url, data).then(() => {
        Swal.fire(
            'Successful!',
            'Your payment information updated successfully.',
            'success'
        );
        this.loadCourse();
      })
    },
    submitCoupon() {
      const url = 'validate-coupon'
      let data = {
        code: this.inputCoupon,
        course_id: this.course.id
      }
      axios.post(url, data).then((response) => {
        if (response.data.discount) {
          this.coupon = response.data
          this.errorMessage = ''
        } else {
          this.errorMessage = 'The coupon code is not valid'
        }
      })
    },
    checkPayment() {
      if (this.course.id) {
        const url = 'payment?course=' + this.course.id;
        axios.get(url).then((response) => {
          if (response.data.id) {
            this.payment = response.data;
            this.phone = this.payment.phone || '';
            this.transaction_id = this.payment.transaction_id || '';
          }
        }).catch((error) => {
          console.log(error);
        })
      }
    },
    loadSettings() {
      const url = 'settings'
      axios.get(url).then((response) =>
          this.settings = response.data)
    },
  },
  mounted() {
    this.loadCourseOnly();
  },
  watch: {
    course() {
      this.checkPayment()
      this.loadSettings()
    },
  }
}
</script>